<template>
  <div class="home">
    <div class="reader-overlay" v-show="scanning">
      <div id="reader"></div>
    </div>
    <div>
      <button class="ui blue button" @click="scan()" v-show="!code">Scan a QR Code</button>
    </div>
    <h2 class="code">{{ code }}</h2>
    <div class="ui segment">
      <a class="ui blue button" href="https://oauth.boruta.patatoid.fr/oauth/authorize?client_id=00000000-0000-0000-0000-000000000001&redirect_uri=https://wallet.boruta.patatoid.fr/siopv2&scope=openid&response_type=code&client_metadata={}&state=qrm0c4xm&relying_party_redirect_uri=http://admin.boruta.patatoid.fr/oauth-callback">Connect to boruta staging</a>
    </div>
    <h2>Params</h2>
    {{ params }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Html5QrcodeScanner, Html5QrcodeScanType } from 'html5-qrcode'

type HomeData = {
  qrScanner: null | Html5QrcodeScanner
  code: string
  scanning: boolean
}

export default defineComponent({
  name: 'HomeView',
  components: {},
  data () {
    return {
      qrScanner: null,
      code: '',
      scanning: false
    } as HomeData
  },
  mounted () {
    const config = {
      fps: 10,
      rememberLastUsedCamera: true,
      // Only support camera scan type.
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA]
    }


    this.qrScanner = new Html5QrcodeScanner("reader", config, true)
  },
  computed: {
    params () {
      return this.$route.query
    }
  },
  methods: {
    scan () {
      this.scanning = true
      function onScanSuccess(decodedText, decodedResult) {
        console.log(decodedText)
        console.log(decodedResult)
        this.code = decodedText
        this.scanning = false
      }
      this.qrScanner?.render(onScanSuccess)
    }
  }
})
</script>

<style scoped>
  .home {
    text-align: center;
    .code {
      overflow-wrap: break-word;
    }
    .reader-overlay {
      z-index: 500;
      position: fixed;
      top: 0;
      height: 100vh;
      width: 100vw;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      #reader {
        height: 70vh;
        width: 70vw;
      }
    }
  }
</style>
