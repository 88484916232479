import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-225b4cf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui siopv2 container" }
const _hoisted_2 = ["action"]
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  class: "ui blue button",
  type: "submit"
}
const _hoisted_5 = { class: "ui info message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      method: "POST",
      action: _ctx.redirect_uri
    }, [
      _createElementVNode("input", {
        type: "hidden",
        name: "id_token",
        value: _ctx.id_token
      }, null, 8, _hoisted_3),
      _withDirectives(_createElementVNode("button", _hoisted_4, "Present your credentials", 512), [
        [_vShow, _ctx.redirect_uri]
      ])
    ], 8, _hoisted_2),
    _withDirectives(_createElementVNode("div", _hoisted_5, "Successful presentation", 512), [
      [_vShow, _ctx.params.code]
    ])
  ]))
}